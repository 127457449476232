/*
--------------------------------------------------------------------------
  PRESIK DASH WEB
  Copyright © 2020 Presik SAS, All rights reserved.
--------------------------------------------------------------------------
*/

import React from 'react';
import ReactDOM from 'react-dom';
import AppWeb from './App';

ReactDOM.render(<AppWeb />, document.getElementById('root'));
