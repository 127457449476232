
import React from 'react'
import { Dimmer, Loader, Segment } from 'semantic-ui-react'
import { FormattedMessage as FM } from 'react-intl'

import './LoadingTable.css'

function LoadingTable () {
  return (
    <Segment className='loading-segment'>
      <Dimmer active inverted>
        <Loader inverted>
          <FM id={'board.loading'} />
        </Loader>
      </Dimmer>
    </Segment>
  )
}

export default LoadingTable
