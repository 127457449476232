
import React, { Component } from 'react'
import { Grid, Dropdown, Button } from 'semantic-ui-react'
import { FormattedMessage as FM } from 'react-intl'
import ReactDataSheet from 'react-datasheet'
import moment from 'moment'
import store from 'store'

import SearchBar from './SearchBar'
import client from 'client'
import 'react-datasheet/lib/react-datasheet.css'
import './QuickSheet.css'

class QuickSheet extends Component {
  constructor (props) {
    super(props)
    this.onDataChange = this.onDataChange.bind(this)
    this.getOptions = this.getOptions.bind(this)
    this.sheetRenderer = this.sheetRenderer.bind(this)
    this.setDataSheet = this.setDataSheet.bind(this)
    this.cellRenderer = this.cellRenderer.bind(this)
    this.toSave = this.toSave.bind(this)

    this.sheetStore = new Map()

    this.state = {
      model: props.ctxView['name'],
      headers: [],
      data: [],
      filteredData: [],
      selectedOption: null,
      options: [],
      messageCtx: null,
      validValues: {}
    }
  }

  async setDataSheet (selectedOption) {
    const { model } = this.state
    const args = {
      period: selectedOption
    }
    const [headers, data, validValues] = await client.proxy.get_sheet(model, args)
    const filteredData = data
    console.log(filteredData)
    this.setState({ headers, data, filteredData, selectedOption, validValues })
  }

  async getOptions () {
    const session = store.get('ctxSession')
    const args = {
      date: moment().format('YYYY-MM-DD'),
      company: session.company
    }
    const _periods = await client.proxy.get_method(
      'account.period', 'get_periods', args
    )
    if (_periods) {
      this.setState({
        selectedOption: _periods[0]['id'],
        options: _periods
      })
    }
  }

  componentDidMount () {
    this.getOptions()
  }

  toSave = async () => {
    if (this.sheetStore.size === 0) {
      return
    }
    const data = {
      model: 'surveillance.schedule.shift',
      values: Array.from(this.sheetStore.values()),
    }
    await client.proxy.saveMany(data)
    this.sheetStore.clear()
    const messageCtx = {
      type: 'msgInfo',
      msg: 'board.records_saved'
    }
    this.setState({ messageCtx })
  }

  onChangeSelector = (event, data) => {
    this.setDataSheet(data.value)
  }

  onClickSearch = (target) => {
    const { data, filteredData } = this.state
    const columns = [0, 1, 2]
    let _data = []
    if (target !== '') {
      for (const d of filteredData) {
        for (const c of columns) {
          if (d[c].value && d[c].value.search(target) >= 0) {
            _data.push(d)
            break
          }
        }
      }
    } else {
      _data = data
    }

    if (_data) {
      this.setState({
        filteredData: _data,
      })
    }
  }

  onDataChange = (changes, filter) => {
    const { filteredData, data, validValues } = this.state
    const grid = filteredData.map(row => [...row])
    for (let ch of changes) {
      let val_id
      if (ch.value.length === 0 || ch.value === '0') {
        val_id = 0
      } else {
        val_id = validValues[ch.value]
      }
      if (val_id || val_id === 0) {
        if (ch.value === '0') {
          val_id = null
          ch.value = null
        }
        this.sheetStore.set(ch.cell.id, {id: ch.cell.id, kind: val_id})
      } else {
        return
      }
    }
    changes.forEach(({cell, row, col, value}) => {
      grid[row][col] = {...grid[row][col], value}
      data[row][col] = {...grid[row][col], value}
    })

    this.setState({
      filteredData: grid,
      messageCtx: null
    })
  }

  sheetRenderer = (props) => {
    return (
      <table className='data-grid' >
        <thead>
          <tr className='sheet-head-row'>
            { this.state.headers.map(c => (
              <th key={c.label} style={{width: c.width}} className='sheet-head'>
                {c.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          { props.children }
        </tbody>
      </table>
    )
  }

  cellRenderer = (props) => {
    const { headers } = this.state
    const { attributesRenderer, cell, row, col, editing, updated, style, ...rest } = props
    let backgroundColor = headers[col].color
    if (cell.readOnly && !cell.value) {
      backgroundColor = 'rgb(108, 114, 119)'
    }

    const styleCustom = {
      width: headers[col].width,
      textAlign: headers[col].align,
      paddingLeft: headers[col].paddingLeft || 0,
      backgroundColor: backgroundColor,
      verticalAlign: 'middle',
    }
    let style_ = {...style, ...styleCustom}

    return (
      <td {...rest} style={style_}>
        { props.children }
      </td>
    )
  }

  render () {
    const { messageCtx, filteredData } = this.state

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column className='sheet-tools' computer={4}>
            <Dropdown className='sheet-dropdown' selection labeled
              placeholder='Periodo'
              onChange={this.onChangeSelector}
              options={this.state.options}
            />
          </Grid.Column>
          <Grid.Column className='sheet-tools' computer={8}>
            <Button basic color='blue' onClick={this.toSave} className='sheet-button' >
              <FM id='board.button_save' />
            </Button>
            <SearchBar onClick={this.onClickSearch}/>
          </Grid.Column>
        </Grid.Row>
        { messageCtx &&
          <Grid.Row>
            <Grid.Column id={`sheet-${messageCtx.type}`} width={16} className='sheet-msg'>
              <p className='sheet-msg'><FM id={messageCtx.msg} /></p>
            </Grid.Column>
          </Grid.Row>
        }
        <Grid.Row className='sheet-main-row'>
          <Grid.Column className='sheet-col' width={16}>
            <ReactDataSheet
              cellRenderer={this.cellRenderer}
              sheetRenderer={this.sheetRenderer}
              data={filteredData}
              valueRenderer={(cell) => cell.value}
              onCellsChanged={changes => this.onDataChange(changes)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default QuickSheet
