
import React from 'react'

import './Chip.css'

class Eval {
  safeEval(args) {
    this.eval(args)
  }
  eval() {
  }
}

function Chip (props) {
  let color = '#717171'
  if (props.value) {
    for (const val of props.color) {
      let operator = val[0]
      let operand = val[1]

      const ctx_domain = `'${props.value}' ${operator} '${operand}'`
      if (eval(ctx_domain)) {
        color = val[2]
        break
      }
    }
  }

  return (
    <div className="chip" style={stylesCtx(color)} title={props.value}>
      <div className="chip-content" >{props.value}</div>
    </div>
  )
}

function stylesCtx (color) {
  return {
    cursor: 'inherit',
    display: 'flex',
    padding: 12,
    align: 'center',
    backgroundColor: color,
    color: 'white',
  }
}

export default Chip
