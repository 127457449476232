import React from 'react';
import {Grid, Button} from 'semantic-ui-react';
import {FormattedMessage as FM} from 'react-intl';

import './ButtonAction.css';

function ButtonAction(props) {
  const recordData = {
    id: props.record.id,
  };

  return (
    <Button
      color="olive"
      key={props.value}
      className="button-col-table"
      onClick={() => props.onClick(props.name, recordData)}>
      {<FM id={props.label} />}
    </Button>
  );
}

export default ButtonAction;
