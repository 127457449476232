import React from 'react';
import {Icon, Button, Modal} from 'semantic-ui-react';
import {FormattedMessage as FM} from 'react-intl';

import './Modal.css';

const ICON = {
  success: {name: 'check', color: 'green'},
  info: {name: 'info', color: 'blue'},
  warning: {name: 'warning circle', color: 'orange'},
  error: {name: 'cancel', color: 'red'},
};

function MeModal(props) {
  const buttons = {
    cancel: (
      <Button
        key="close"
        color="red"
        className="modal-button"
        onClick={props.onClose}>
        <FM id="board.dialog.button_cancel" />
      </Button>
    ),
    ok: (
      <Button
        key="ok"
        color="blue"
        className="modal-button"
        onClick={props.onAccept}>
        <FM id="board.dialog.button_ok" />
      </Button>
    ),
    close: (
      <Button
        basic
        key="close"
        color="blue"
        className="modal-button"
        onClick={props.onClose}>
        <FM id="board.dialog.button_close" />
      </Button>
    ),
  };

  const dialogButtons = props.buttons.map((name) => {
    return buttons[name];
  });

  return (
    <Modal open={props.open}>
      <Modal.Header className="modal-header">
        {props.titleModal && (
          <div>
            <p className="modal-header-text">
              {' '}
              <FM id={props.titleModal} />{' '}
            </p>
          </div>
        )}
      </Modal.Header>
      <Modal.Content className="modal-content">
        {props.type && (
          <Icon
            color={ICON[props.type].color}
            size="huge"
            name={ICON[props.type].name}
          />
        )}
        <Modal.Description className="modal-content-text">
          <FM id={props.msg} />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions className="modal-actions">{dialogButtons}</Modal.Actions>
    </Modal>
  );
}

export default MeModal;
