import React from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import {Icon, Menu, Segment, Sidebar} from 'semantic-ui-react';
import {FormattedMessage as FM} from 'react-intl';

import './Sidebar.css';
import logo from 'assets/img/logo.png';

const DashSidebar = ({...props}) => {
  const {routes, visible} = props;

  const menus = routes.map((route, key) => {
    return (
      <Menu.Item key={key} as={NavLink} to={route.layout}>
        <Icon name={route.icon} />
        <FM id={route.name} />
      </Menu.Item>
    );
  });

  return (
    <Sidebar.Pushable as={Segment} className="sidebar-pushable">
      <Sidebar
        as={Menu}
        icon="labeled"
        className="sidebar-body"
        inverted
        vertical
        animation="overlay"
        visible={visible}>
        <img src={logo} alt="logo" className="sidebar-img" />
        {menus}
      </Sidebar>
    </Sidebar.Pushable>
  );
};

export default withRouter(DashSidebar);
