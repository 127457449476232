import React from 'react';
import {Grid, Button} from 'semantic-ui-react';

import './ButtonForm.css';

function ButtonForm(props) {
  return (
    <Grid.Column
      mobile={16}
      table={8}
      computer={8}
      className="grid-col-button-form">
      <Button
        color="olive"
        disabled={props.readonly}
        key={props.value}
        className="button-col-form"
        onClick={() => props.onClick(props.value, props.msg_ok, props.redirect)}
        content={props.label}
      />
    </Grid.Column>
  );
}

export default ButtonForm;
