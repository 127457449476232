import React from 'react';
import {Table} from 'semantic-ui-react';
import {FormattedMessage as FM} from 'react-intl';

import './TableHeader.css';

function TableHeader(props) {
  let cellButtonStart = [];
  let cellButtonEnd = [];
  if (props.buttons) {
    for (const cb of props.buttons) {
      if (cb['action'] === 'update') {
        cellButtonStart.push(cb);
      } else {
        cellButtonEnd.push(cb);
      }
    }
  }

  function headerCellOrdered(index, field) {
    return (
      <Table.HeaderCell
        key={index}
        style={stylesCtx(field.width)}
        sorted={props.orderField === field.name ? props.orderDirection : null}
        onClick={props.handleSort(field.name)}>
        {<FM id={`model.${props.model}.${field.name}`} />}
      </Table.HeaderCell>
    );
  }

  function headerCell(index, field) {
    return (
      <Table.HeaderCell key={index} style={stylesCtx(field.width)}>
        {<FM id={`model.${props.model}.${field.name}`} />}
      </Table.HeaderCell>
    );
  }

  function cellButtons(buttons) {
    return buttons.map((button) => {
      return (
        <Table.HeaderCell key={button.action} className="header-button">
          <FM id={button.tooltip} />
        </Table.HeaderCell>
      );
    });
  }

  return (
    <Table.Header>
      <Table.Row key={'header'}>
        {cellButtons(cellButtonStart)}
        {props.sortable
          ? props.fields.map((field, index) => headerCellOrdered(index, field))
          : props.fields.map((field, index) => headerCell(index, field))}
        {cellButtons(cellButtonEnd)}
      </Table.Row>
    </Table.Header>
  );
}

function stylesCtx(width) {
  return {
    width: width,
    textAlign: 'center',
  };
}

export default TableHeader;
