
import React, { useState } from 'react'
import { Input } from 'semantic-ui-react'

import './SearchBar.css'

function SearchBar (props) {
  const [value, setValue] = useState('')

  function onChange(event, data) {
    setValue(data.value)
  }

  function searchClicked(event) {
    const _event = event.nativeEvent
    // event.keyCode = 13 is Enter
    if (event.keyCode === 13 || (_event && _event.type === 'click')) {
      props.onClick(value)
    }
  }

  return (
    <Input
      value={value}
      className='search-bar'
      loading={false}
      placeholder='Search...'
      onChange={onChange}
      onKeyDown={searchClicked}
      action={{ icon: 'search', onClick: searchClicked }}
    />
  )
}

export default SearchBar
