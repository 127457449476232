
import React from 'react'
import { FormattedMessage as FM } from 'react-intl'
import { Icon, Dropdown } from 'semantic-ui-react'

import './FilterTable.css'

function FilterTable (props) {

  if (!props.filterTags) return null

  function onClick (event, data) {
    const filterTag = `${data.text.props.id}`
    props.onClickFilter(data.value, filterTag)
  }

  function getFilterTag() {
    return (
      <div className='div-table-filter'>
        <FM id={props.filterValue} />
        <span>({props.countRecords})</span>
      </div>
    )
  }

  return (
      <Dropdown floating labeled button className='icon drop-large'
        trigger={getFilterTag()}
        icon={<Icon name='filter' className='icon-filter'/>}>
        <Dropdown.Menu>
          {props.filterTags.map(option => (
            <Dropdown.Item key={option.value} {...option} onClick={onClick} />
          ))}
        </Dropdown.Menu>
      </Dropdown>
  )
}

export default FilterTable
