
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { IntlProvider } from 'react-intl'

import Loading from 'components/Tools/Loading'
import Board from 'components/Board/Board'
import client from 'client'
import intl from 'i18n/messages'

class WebForm extends Component {
  constructor (props) {
    super(props)
    let [_, localeLang] = intl.getBaseLang()
    this.getTranslation = this.getTranslation.bind(this)
    this.getFormView = this.getFormView.bind(this)

    this.state = {
      localeLang: localeLang,
      translation: null,
      formView: null,
    }
  }

  async componentDidMount () {
    const formView = await this.getFormView()
    const translation = await this.getTranslation()
    this.setState({formView, translation})
  }

  async getTranslation() {
    const messages_ = await intl.messages()
    return messages_
  }

  async getFormView() {
    const { model } = this.props.match.params
    const formView = await client.proxy.get_form(model)
    return formView
  }

  render () {
    const { formView, translation, localeLang } = this.state
    console.log('XX >>', translation, formView)
    return (
      translation && formView?
      <IntlProvider locale={localeLang} messages={translation}>
        <Board viewType='webform' ctxView={formView}
          model={this.props.match.params.model}/>
      </IntlProvider>
      : <Loading />
    )
  }
}

export default withRouter(WebForm)
