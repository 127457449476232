import React from 'react';
import {Dropdown} from 'semantic-ui-react';
import {FormattedMessage as FM} from 'react-intl';

import FormField from './FormField';

function DropdownField(props) {
  function selectedItem(event, data) {
    props.onChange(data.name, data.value);
  }

  if (props.translate) {
    for (let d of props.data_source) {
      d.text = <FM id={`model.${props.model}.${d.value}`} key={d.value} />;
    }
  }

  return (
    <FormField {...props}>
      <Dropdown
        fluid
        search
        selection
        disabled={props.readonly}
        value={props.value}
        name={props.name}
        key={props.name}
        error={props.error}
        onChange={selectedItem}
        options={props.data_source || []}
      />
    </FormField>
  );
}

export default DropdownField;
